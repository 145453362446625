import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useProjectDetailData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: projectDetailDataJson {
        seo {
          description
          shortDescription
        }
        cover {
          placeholder {
            text
          }
        }
        details {
          headline
          information {
            type
            name
            icon {
              src {
                publicURL
              }
              alt
            }
          }
        }
        backBtns {
          top {
            text
            to
          }
          bottom {
            text
            to
          }
        }
        image {
          copyright
        }
      }
      de: deProjectDetailDataJson {
        seo {
          description
          shortDescription
        }
        cover {
          placeholder {
            text
          }
        }
        details {
          headline
          information {
            type
            name
            icon {
              src {
                publicURL
              }
              alt
            }
          }
        }
        backBtns {
          top {
            text
            to
          }
          bottom {
            text
            to
          }
        }
        image {
          copyright
        }
      }
    }
  `);

  return data[locale];
};
