// Fade in for regular items
export const fadeIn = {
  initial: {
    opacity: 0,
    y: 20
  },
  animate: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: 'easeOut',
      delay: custom
    }
  })
};

// Stagger variant for parent element
export const childrenStagger = {
  initial: {},
  animate: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 1.4
    }
  }
};

// Fade in for staggered items
export const fadeInChildren = {
  initial: {
    opacity: 0,
    y: 20
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: 'easeOut'
    }
  }
};
