import React from 'react';

// External Components
import {
  Section,
  Heading,
  FlexWrapper,
  Paragraph,
  GridItem,
  Box,
  fullWidthMinusMargins
} from '@thepuzzlers/pieces';
import { useLocation } from '@reach/router';
import { useIntl } from 'gatsby-theme-thepuzzlers-intl';
import { SEO } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  ImageContainer,
  Vector,
  BackButton,
  SectionSpacer,
  ImageWithClipPath,
  VideoPlayer
} from 'components';

// Markup Data
import { useProjectDetailData } from './useProjectDetailData';

// Animations
import { fadeIn, childrenStagger, fadeInChildren } from './animations';
import { useSiteMetadata } from 'gatsby-theme-thepuzzlers-core';

const ProjectDetail = (props) => {
  const locale = props.pageContext.locale;
  const data = props.pageContext.data[locale].data;
  const uid = props.pageContext.data[locale].uid;

  const markupData = useProjectDetailData();

  const seoData = {
    title: data.name.text,
    description: markupData.seo.description,
    shortTitle: `${data.name.text} | MDL Expo International`,
    shortDescription: markupData.seo.shortDescription,
    url: props.location.href
  };

  return (
    <>
      <CustomSEO {...seoData} />
      <ContentWrapper uid={uid}>
        <Button
          primary
          custom={0.4}
          variants={fadeIn}
          {...markupData.backBtns.top}
        />
        <Spacer
          height={['13.80%', '8.53%', '11.31%', '6.39%', '8.01%', '6.25%']}
        />
        <Headline data={data.name.text} />
        {data?.video?.html ? (
          <VideoPlayer
            video={data.video}
            isVertical={data.thevideoisvertical}
          />
        ) : data.cover_image.gatsbyImageData ? (
          <CoverImage
            {...data.cover_image}
            copyrightTitle={markupData.image.copyright}
          />
        ) : (
          <CoverPlaceholder data={markupData.cover.placeholder.text} />
        )}
        <DetailsWrapper details={markupData.details} projectData={data} />
        {(data.images[0]?.image?.gatsbyImageData ||
          data?.vertical_images[0]?.image?.gatsbyImageData) && (
          <ImagesWrapper
            prismicLandscapeImages={data.images}
            prismicVerticalImages={data.vertical_images}
            copyrightTitle={markupData.image.copyright}
          />
        )}
        <Button {...markupData.backBtns.bottom} />
      </ContentWrapper>
      <SectionSpacer
        spacing={['43.90%', '27.14%', '28.28%', '20.34%', '40.04%', '31.26%']}
      />
    </>
  );
};

const CustomSEO = (seoData) => {
  const siteMetadata = useSiteMetadata();

  const { pathname } = useLocation();

  let englishProjectDetailPath;
  let germanProjectDetailPath;

  if (pathname.match(/\/en\/projects\/\w+/)) {
    englishProjectDetailPath = pathname;
    germanProjectDetailPath =
      '/projekte' + pathname.slice('/en/projects'.length);
  }

  if (pathname.match(/\/projekte\/\w+/)) {
    englishProjectDetailPath =
      '/en/projects' + pathname.slice('/projekte'.length);
    germanProjectDetailPath = pathname;
  }

  // sets alternate links manually because the translation of the path
  // can not be handled by the standard SEO component
  const alternateLinks = [
    {
      rel: 'alternate',
      hreflang: 'de',
      href: siteMetadata.url + germanProjectDetailPath
    },
    {
      rel: 'alternate',
      hreflang: 'en',
      href: siteMetadata.url + englishProjectDetailPath
    },
    {
      rel: 'alternate',
      hreflang: 'x-default',
      href: siteMetadata.url + germanProjectDetailPath
    }
  ];

  return <SEO {...seoData} customAlternateLinks={alternateLinks} />;
};

export default ProjectDetail;

// Elements

const ContentWrapper = ({ children, uid }) => (
  <Section
    id={`project-detail-${uid}`}
    // Animation values
    initial="initial"
    animate="animate">
    {children}
  </Section>
);

const Headline = ({ data }) => (
  <Heading
    as="h1"
    type="h1-900-100"
    variant="bold"
    initial={{ y: 60, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{
      duration: 1
    }}
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      textAlign: 'center',
      textTransform: 'uppercase',
      hyphens: 'auto',
      overflowWrap: 'break-word',
      wordWrap: 'break-word'
    }}>
    {data}
  </Heading>
);

const CoverImage = ({ gatsbyImageData, alt, copyright, copyrightTitle }) => (
  <Box
    sx={{
      gridColumn: [
        '1/13',
        '1/13',
        '1/25',
        '2/span 22',
        '3/span 20',
        '3/span 20'
      ],
      mt: ['10.67%', '7.76%', '5.66%', '4.65%', '4.00%', '2.97%'],
      mx: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0]
    }}>
    <ImageWithClipPath
      className="project-detail__cover-image"
      src={gatsbyImageData}
      alt={alt}
      customDelay={0.6}
      sx={{
        aspectRatio: '1.3',
        overflow: 'hidden'
      }}
    />
    {copyright && <ImgCopyright title={copyrightTitle} data={copyright} />}
  </Box>
);

const CoverPlaceholder = ({ data }) => (
  <Heading
    as="h3"
    type="p-1000-200"
    variant="bold"
    // Animation value
    custom={0.8}
    variants={fadeIn}
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      textAlign: 'center',
      textTransform: 'uppercase',
      mt: ['10%', '8%', '8%', '6%', '6%', '4.53%'],
      minHeight: ['8em', '10em', '8em', '6em', '8em', '9em']
    }}>
    {data}
  </Heading>
);

const categoryNames = {
  'Set- und Eventbau': 'Event/Set',
  Ladenbau: 'Shop',
  Messebau: 'Exhibition'
};
const germanCategoryNames = {
  'Set- und Eventbau': 'Event-/Setbau',
  Ladenbau: 'Ladenbau',
  Messebau: 'Messebau'
};

const DetailsWrapper = ({
  details: { headline, information },
  projectData
}) => {
  const { locale } = useIntl();

  return (
    <Box
      className="project-detail__details-wrapper"
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '1/25',
          '2/span 22',
          '3/span 20',
          '3/span 20'
        ],
        mt: ['16.53%', '14%', '15%', '6.35%', '12%', '9.21%']
      }}>
      <Heading
        as="h3"
        type="p-1000-200"
        variant="bold"
        custom={1.2}
        variants={fadeIn}
        sx={{ textTransform: 'uppercase' }}>
        {headline}
      </Heading>
      <Box
        variants={childrenStagger}
        sx={{
          display: 'grid',
          gridTemplateColumns: [
            '1fr',
            '1fr',
            '1fr 1fr',
            '1fr 1fr',
            'repeat(3, 1fr)',
            'repeat(3, 1fr)'
          ],
          gap: [0, 0, '1em', '0.6em', '1em 0.6em', '1em 0.6em'],
          mt: ['6.58%', '4.7%', '4.7%', '4%', '4%', '2.83%']
        }}>
        {information.map(
          (info) =>
            projectData[info.type] &&
            (info.type === 'category' ? (
              <DetailItem
                {...info}
                key={info.type}
                data={
                  locale === 'en'
                    ? categoryNames[projectData.category]
                    : germanCategoryNames[projectData.category]
                }
              />
            ) : (
              <DetailItem
                {...info}
                key={info.type}
                data={
                  info.type === 'name'
                    ? projectData.name.text
                    : projectData[info.type]
                }
              />
            ))
        )}
      </Box>
    </Box>
  );
};

const ImagesWrapper = ({
  prismicLandscapeImages,
  prismicVerticalImages,
  copyrightTitle
}) => {
  function getImages() {
    const horizontalImages = [];
    const verticalImages = [];

    // check again if user misplaced the vertical image to the horizontal image in prismic
    prismicLandscapeImages.forEach((item) => {
      const image = item.image.gatsbyImageData;
      const isVertical = image.height > image.width;

      isVertical ? verticalImages.push(item) : horizontalImages.push(item);
    });

    // check again if user misplaced the horizontal image to the vertical image in prismic
    if (prismicVerticalImages[0]?.image?.gatsbyImageData) {
      prismicVerticalImages.forEach((item) => {
        const image = item.image.gatsbyImageData;
        const isVertical = image.height > image.width;

        isVertical ? verticalImages.push(item) : horizontalImages.push(item);
      });
    }

    const images = [...horizontalImages, ...verticalImages];

    return images;
  }

  const images = getImages();

  return (
    <Box
      className="project-detail__images-wrapper"
      sx={{
        display: 'grid',
        gridTemplateColumns: [
          '1fr',
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr'
        ],
        gridColumn: [
          '1/13',
          '1/13',
          '1/25',
          '2/span 22',
          '3/span 20',
          '3/span 20'
        ],
        gap: ['1em', '1em', '1em', '0.6em', '0.6em', '1em'],
        flexWrap: 'wrap',
        mt: ['16.53%', '14%', '16.53%', '6.35%', '12%', '9.21%'],
        mx: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0]
      }}>
      {images.map(({ image }) => {
        const isVertical =
          image.gatsbyImageData.height > image.gatsbyImageData.width;

        return (
          <Box
            sx={{
              gridColumn: !isVertical && [
                1,
                1,
                '1/span 2',
                '1/span 2',
                '1/span 2',
                '1/span 2'
              ],
              width: '100%'
            }}>
            <ImageContainer
              key={image.url}
              src={image.gatsbyImageData}
              alt={image.alt}
            />
            {image.copyright && (
              <ImgCopyright title={copyrightTitle} data={image.copyright} />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

// Reusable Components

const DetailItem = ({ icon: { src, alt }, name, data }) => (
  <FlexWrapper
    variants={fadeInChildren}
    className="details-wrapper__item"
    sx={{
      alignItems: 'center',
      gap: ['3.76%', '2.5%', '2%', '2%', '2%', '2%']
    }}>
    <Vector
      src={src.publicURL}
      alt={alt ? alt : ''}
      sx={{ width: ['22.57%', '18%', '28%', '26%', '40%', '32%'] }}
    />
    <Box>
      <Paragraph type="p-300-150-a">{name}</Paragraph>
      <Paragraph type="p-300-150-b" variant="bold">
        {data}
      </Paragraph>
    </Box>
  </FlexWrapper>
);

const Button = ({ primary, text, ...props }) => (
  <BackButton
    primary={primary}
    text={text}
    sx={{
      gridColumn: primary
        ? [
            '1/span 5',
            '1/span 3',
            '1/span 5',
            '1/span 4',
            '1/span 4',
            '1/span 3'
          ]
        : ['1/span 9', '1/span 10', '1/25', '1/25', '1/25', '1/25'],
      justifySelf: primary
        ? 'start'
        : ['start', 'start', 'center', 'center', 'center', 'center'],
      mt: primary
        ? '3.08%'
        : ['34.88%', '18.68%', '11.31%', '8.01%', '8.01%', '13.83%']
    }}
    {...props}
  />
);

const Spacer = ({ height }) => (
  <GridItem className="spacer" sx={{ mt: height }} />
);

const ImgCopyright = ({ title, data }) => (
  <Paragraph
    type="p-300-150-a"
    custom={1}
    variants={fadeIn}
    sx={{ mt: '0.8%', mr: ['4%', 0, 0, 0, 0, 0], textAlign: 'right' }}>
    {title}: {data}
  </Paragraph>
);
